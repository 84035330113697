<template>
  <div>
    <v-dialog v-model="openDialog" max-width="1000px" persistent>
      <v-card>
        <v-toolbar color="teal lighten-2" dark>
          <v-toolbar-title class="ml-2">Detalle de Empresa</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="mb-1" fab x-small color="red" dark @click="closeDialog"
            >X</v-btn
          >
        </v-toolbar>
        <v-container>
          <v-card>
            <v-form ref="refDialogDetailEmpresa" class="pa-2">
              <v-row>
                <v-col md="3">
                  <v-text-field
                    outlined
                    dense
                    readonly
                    v-model="vmRutEmpresa"
                    label="Rut Empresa..."
                    :rules="[(v) => !!v || 'Requerido.']"
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    outlined
                    dense
                    readonly
                    v-model="vmRazonSocial"
                    label="Razon Social..."
                    :rules="[(v) => !!v || 'Requerido.']"
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    outlined
                    dense
                    readonly
                    v-model="vmGiro"
                    label="Giro..."
                    :rules="[(v) => !!v || 'Requerido.']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="3">
                  <v-text-field
                    outlined
                    dense
                    readonly
                    v-model="vmDireccion"
                    label="Dirección..."
                    :rules="[(v) => !!v || 'Requerido.']"
                  ></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field
                    outlined
                    dense
                    readonly
                    v-model="vmCiudad"
                    label="Ciudad..."
                    :rules="[(v) => !!v || 'Requerido.']"
                  ></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field
                    outlined
                    dense
                    readonly
                    v-model="vmRutRepre"
                    label="Rut Representante..."
                    :rules="[(v) => !!v || 'Requerido.']"
                  ></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field
                    outlined
                    dense
                    readonly
                    v-model="vmNombreRepre"
                    label="Nombre Representante..."
                    :rules="[(v) => !!v || 'Requerido.']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import EmpresaService from "../../../service/api/empresa/index"
export default {
  props: {
    DatosEmpresa: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    validateForm() {
      return this.$refs.refFormEditCuentas.validate();
    },
    resetForm() {
      return this.$refs.refFormEditCuentas.reset();
    },
  },

  data() {
    return {
      items: [],
      openDialog: false,
      vmRutEmpresa: "",
      vmRazonSocial: "",
      vmGiro: "",
      vmDireccion: "",
      vmRutRepre: "",
      vmNombreRepre: "",
      vmCiudad: "",
    };
  },
  methods: {
    getValues() {
      this.vmRutEmpresa = this.DatosEmpresa.rut;
      this.vmRazonSocial = this.DatosEmpresa.razon_social;
      this.vmGiro = this.DatosEmpresa.giro;
      this.vmDireccion = this.DatosEmpresa.direccion;
      this.vmRutRepre = this.DatosEmpresa.rut_repre;
      this.vmNombreRepre = this.DatosEmpresa.nombre_repre;
      this.vmCiudad = this.DatosEmpresa.ciudad;
    },
    closeDialog() {
      this.openDialog = false;
    },
  },
  watch: {
    DatosEmpresa(val) {
      if (val) {
        this.getValues();
      }
    },
  },
};
</script>

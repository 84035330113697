<template>
  <div>
    <v-dialog v-model="openDialog" max-width="1000px" persistent>
      <v-card>
        <v-toolbar color="teal lighten-2" dark>
          <v-toolbar-title class="ml-2">Creación de Empresa</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="mb-1" fab x-small color="red" dark @click="closeDialog"
            >X</v-btn
          >
        </v-toolbar>
        <v-container>
          <v-card>
            <v-form ref="refDialogIngresaEmpresa" class="pa-2">
              <v-row>
                <v-col md="3">
                  <v-text-field
                    outlined
                    dense
                    v-model="vmRutEmpresa"
                    label="Rut Empresa...(1333666-5)"
                    :rules="[
                      (v) => !!v || 'Requerido.',
                      (v) => validaRut(v) || 'Rut mal ingresado',
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    outlined
                    dense
                    v-model="vmRazonSocial"
                    label="Razon Social..."
                    :rules="[(v) => !!v || 'Requerido.']"
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    outlined
                    dense
                    v-model="vmGiro"
                    label="Giro..."
                    :rules="[(v) => !!v || 'Requerido.']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="3">
                  <v-text-field
                    outlined
                    dense
                    v-model="vmDireccion"
                    label="Dirección..."
                    :rules="[(v) => !!v || 'Requerido.']"
                  ></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field
                    outlined
                    dense
                    v-model="vmCiudad"
                    label="Ciudad..."
                    :rules="[(v) => !!v || 'Requerido.']"
                  ></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field
                    outlined
                    dense
                    v-model="vmRutRepre"
                    label="Rut Representante...(1333666-5)"
                    :rules="[
                      (v) => !!v || 'Requerido.',
                      (v) => validaRut(v) || 'Rut mal ingresado',
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field
                    outlined
                    dense
                    v-model="vmNombreRepre"
                    label="Nombre Representante..."
                    :rules="[(v) => !!v || 'Requerido.']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <div class="text-center">
                <v-btn class="mt-2" @click="submitCreate" dark color="success"
                  >Crear</v-btn
                >
              </div>
            </v-form>
          </v-card>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EmpresaService from "../../../service/api/empresa/index";
export default {
  name: "dialog-create-empresa",
  // props: {},
  data: function () {
    return {
      openDialog: false,
      vmRutEmpresa: "",
      vmRazonSocial: "",
      vmGiro: "",
      vmDireccion: "",
      vmRutRepre: "",
      vmNombreRepre: "",
      vmCiudad: "",
    };
  },
  computed: {
    validateForm() {
      return this.$refs.refDialogIngresaEmpresa.validate();
    },
    resetForm() {
      return this.$refs.refDialogIngresaEmpresa.reset();
    },
  },
  methods: {
    validaRut(rutCompleto) {
      if (rutCompleto) {
        rutCompleto = rutCompleto.replace("‐", "-");
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
        var tmp = rutCompleto.split("-");
        var digv = tmp[1];
        var rut = tmp[0];
        if (digv == "K") digv = "k";

        return this.dv(rut) == digv;
      }
    },

    dv(T) {
      var M = 0,
        S = 1;
      for (; T; T = Math.floor(T / 10))
        S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
      return S ? S - 1 : "k";
    },

    closeDialog() {
      this.openDialog = false;
      this.resetForm;
    },
    async submitCreate() {
      if (this.validateForm) {
        let data = {};
        data.rut = this.vmRutEmpresa;
        data.razon_social = this.vmRazonSocial;
        data.giro = this.vmGiro;
        data.direccion = this.vmDireccion;
        data.rut_repre = this.vmRutRepre;
        data.nombre_repre = this.vmNombreRepre;
        data.ciudad = this.vmCiudad;
        let response = await EmpresaService.create(data);
        if (response.status === 201) {
          this.closeDialog();
          this.$emit("successResponse");
        } else {
          this.closeDialog();
          this.$emit("errorResponse", response);
        }
      }
    },
  },
};
</script>
